import { Link } from "gatsby"
import React from "react"

const Footer = ( ) => (
  <React.Fragment>
    <footer className="footer has-background-black p-2" style={{overflow:"hidden"}}>
      <div className="container">
        <div className="columns">
          <div className="column has-text-left" >
            <p><strong className="has-text-grey">© FUSION DEVELOPMENT LTD</strong> </p>
            <p><Link style={{color:"#878787", textDecoration:"underline"}} to="/termsandconditions">T&amp;C's</Link>
            <span className="ml-6"><Link style={{color:"#878787", textDecoration:"underline"}} to="/privacystatement">Privacy Policy</Link></span></p>
          </div>
          <div className="column has-text-right">
            <p>Icons provided by <a style={{color:"#878787", textDecoration:"underline"}} target="_blank" rel="noreferrer" href="https://icons8.com/">Icons 8</a></p>
          </div>
        </div>
      </div>
    </footer>
  </React.Fragment>
)

export default Footer
