import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Fusion from "../images/f-logo.svg"

const Header = () => {
const [isActive, setisActive] = React.useState(false)
  
  return (
  <React.Fragment>
  <header className="p-5 section">
  <nav className='navbar container' role='navigation' aria-label='main navigation'>
    <div className='navbar-brand'>
    <div className="logo">
      <Link to='/'>
          <img src={Fusion} style={{maxHeight:"70px"}} alt="logo" className="logo" />
          <div  className="fusion" ><div className="green" >FUSION</div>
          <div>- DEVELOPMENT -</div></div>
      </Link>
      {/* <div className="breadcrumb mt-2" aria-label="breadcrumbs">
      <ul>
        <li><a href="#">Fusion</a></li>
        <li><a href="#">Documentation</a></li>
      </ul>
        </div> */}
    </div>

        <a
          onClick={() => {
            setisActive(!isActive)
          }}
          role='button'
          tabIndex={0}
          className={`navbar-burger burger ${isActive ? 'is-active' : ''}`}
          aria-label='menu'
          aria-expanded='false'
          data-target='navbarBasicExample'
        >
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
          <span aria-hidden='true'></span>
        </a>
      </div>
      <div id='navbarBasicExample' className={`navbar-menu ${isActive ? 'is-active' : ''}`}>
        <div className='navbar-end'>
          <div className='navbar-item'>
            <Link to='/' className='navbar-item'>
              Home
            </Link>
            <Link to='/aboutus' className='navbar-item'>
              About Us
            </Link>
            <Link to='/services' className='navbar-item'>
              Services 
            </Link>
            <Link to='/theteam' className='navbar-item'>
              The Team
            </Link>
            <Link to='/contactus' className='navbar-item'>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </nav>
  </header>
  </React.Fragment>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
