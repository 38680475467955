import { Link } from "gatsby"
import React from "react"
import Fusion from "../images/f-logo.svg"

const LowerContent = ({ siteTitle }) => (
  <React.Fragment>
  <section className="section has-background-dark p-6">
    <div className="container">
  <div className="columns">
  <div className="column is-three-quarters has-text-left-mobile ">
  <div className='navbar-brand '>
    <div className="logo">
  <Link to='/'>
          <img src={Fusion} style={{maxHeight:"70px"}} alt="logo" className="logo" />
          <div  className="fusion" ><div className="green" >FUSION</div>
          <div className="pb-5">- DEVELOPMENT -</div></div>
          <p className="ml-6 pb-2 has-text-grey is-size-5">Discover<span className="has-text-primary">.</span></p>
                <p className="ml-6 pb-2 has-text-grey is-size-5">Design<span className="has-text-primary">.</span></p>
                <p className="ml-6 has-text-grey is-size-5">Deliver<span className="has-text-primary">.</span></p>
      </Link>
      </div>
      </div>
  </div>
  <div  className="column has-text-left-mobile ">
      <div className="p-5" style={{border:"1px solid grey"}}>
        <p className="has-text-white pb-2">Contact us</p>
        <p className="pb-2">Fusion Development,</p>
        <p className="pb-2">1st Floor, Forum 4,</p>
        <p className="pb-2">Grenville Street,</p>
        <p className="pb-2">St Helier.</p>
        <p className="pb-2">Jersey JE2 4UF</p>
        <p className="has-text-primary"> <a className="enquiryhover" style={{color:"#00a79d"}} href="mailto:enquiries@fusion.je">enquiries@fusion.je</a></p>
      </div>
    </div>


</div>
</div>
  </section>
  </React.Fragment>
)

export default LowerContent;
